<template>
  <div>
    <KTCodePreview v-bind:title="'In Hóa đơn'">
      <template v-slot:toolbar>
        <b-button
          size="sm"
          variant="primary"
          class="font-weight-bolder"
          @click="printData"
          v-if="bills.length"
        >
          <i class="fas fa-print"></i>
          In hóa đơn
        </b-button>
      </template>
      <template v-slot:preview>
        <div>
          <b-row>
            <b-col cols="4">
              <b-form-textarea
                v-model="searchIds"
                placeholder="Nhập id..."
                rows="3"
              ></b-form-textarea>
            </b-col>
            <b-col cols="2">
              <b-overlay
                :show="isBusy"
                rounded
                opacity="0.6"
                class="d-block mb-2"
              >
                <template #overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner variant="light" small type="grow"></b-spinner>
                  </div>
                </template>
                <b-button
                  size="sm"
                  variant="primary"
                  class="font-weight-bolder d-block"
                  @click="getBill"
                  :disabled="searchIds || isBusy ? false : true"
                >
                  Lấy hoá đơn
                </b-button>
              </b-overlay>
              <b-overlay :show="isBusy" rounded opacity="0.6" class="d-block">
                <template #overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner variant="light" small type="grow"></b-spinner>
                  </div>
                </template>
                <b-button
                  size="sm"
                  variant="warning"
                  class="font-weight-bolder d-block mt-2"
                  @click="removeBill"
                  :disabled="searchIds || isBusy ? false : true"
                >
                  Xoá hoá đơn
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <span class="mr-4"
                >Tổng số hoá đơn:<b class="text-primary">
                  {{ totalBills }}</b
                ></span
              >
              <span
                >Tổng số hoá đơn lỗi:
                <b class="text-danger">{{ totalBillsError }}</b></span
              >
            </b-col>
          </b-row>
          <hr />
        </div>
        <div id="printMe" v-if="bills.length">
          <div v-for="(bill, index) in bills" :key="index">
            <b-row class="break-page-print">
              <div
                v-if="bill.notExist"
                style="margin-left: 20px; margin-right: 20px; width: 100%"
              >
                <h5
                  class="label-color text-center font-size-print text-danger"
                  style="font-size: 18px; margin-bottom: 10px"
                >
                  PHIẾU BÁN HÀNG {{ bill.billNumber }} KHÔNG TỒN TẠI
                </h5>
              </div>
              <div
                v-else
                style="margin-left: 20px; margin-right: 20px; width: 100%"
              >
                <h5
                  class="label-color text-center font-size-print"
                  style="font-size: 18px; margin-bottom: 10px"
                >
                  PHIẾU BÁN HÀNG
                </h5>
                <b-row style="margin-top: 50px">
                  <b-col md="8">
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print">Chi nhánh</span>
                      : {{ bill.storeAddress }}
                    </p>
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print"
                        >Tổng đài CSKH</span
                      >: 1800 6018
                    </p>
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print">Đơn hàng</span>
                      : {{ bill.billNumber }}
                    </p>
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print">Ngày</span>
                      : {{ bill.createdAt }}
                    </p>
                  </b-col>
                  <b-col md="4">
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print"
                        >Tên khách hàng</span
                      >
                      : {{ bill.customerName }}
                    </p>
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print"
                        >Điện thoại</span
                      >
                      : {{ bill.customerPhone }}
                    </p>
                    <p class="content-info font-size-print">
                      <span class="label-color font-size-print">Địa chỉ</span>
                      : {{ bill.customerAddress }}
                    </p>
                  </b-col>
                </b-row>

                <p class="label-color font-size-print">Danh sách sản phẩm</p>

                <table class="table table-bordered-print col-md-12 mb-10">
                  <tr>
                    <th class="label-color text-center font-size-print">STT</th>
                    <th class="label-color font-size-print">Tên sản phẩm</th>
                    <th class="label-color font-size-print">Bảo hành</th>
                    <th class="label-color font-size-print">SL</th>
                    <th class="label-color font-size-print">Đơn giá</th>
                    <th class="label-color font-size-print">Chiết khấu</th>
                    <th class="label-color font-size-print">Thành tiền</th>
                  </tr>
                  <tr v-for="(item, index) in bill.listBillItem" :key="index">
                    <td class="text-center align-middle">
                      <p v-if="item.billItemType === 1">
                        {{ item.stt }}
                      </p>
                    </td>
                    <td>
                      <p
                        v-show="
                          item.type === 1 || item.type === 2 || item.type === 4
                        "
                        style="font-weight: 550; font-size: 13px"
                        class="m-0"
                        v-if="item.billItemType === 1"
                      >
                        {{ item.name }}
                      </p>

                      <p
                        v-if="item.type === 2"
                        style="font-style: italic; font-size: 12px"
                      >
                        IMEI: {{ item.imeiCode }}
                      </p>
                      <p
                        v-show="
                          item.type === 1 || item.type === 2 || item.type === 4
                        "
                        style="font-weight: 550; font-size: 13px"
                        class="ml-0"
                        v-else-if="item.billItemType === 2"
                      >
                        <i
                          style="color: rgb(24, 28, 50)"
                          class="fas fa-gift ml-4"
                        ></i>
                        {{ item.name }}
                      </p>
                      <p
                        v-show="
                          item.type === 1 || item.type === 2 || item.type === 4
                        "
                        style="font-weight: 550; font-size: 13px"
                        class="ml-0"
                        v-else-if="item.billItemType === 3"
                      >
                        <i
                          style="color: rgb(24, 28, 50)"
                          class="fas fa-tags ml-4"
                        ></i>
                        {{ item.name }}
                      </p>
                      <div
                        class="table-responsive"
                        v-show="
                          item.comboItems !== undefined &&
                          item.comboItems.length > 0
                        "
                      >
                        <table>
                          <tr>
                            <td style="border: none">
                              <p
                                style="font-weight: 550; font-size: 13px"
                                class="m-0"
                              >
                                {{ item.name }}
                              </p>
                            </td>
                          </tr>
                          <tr
                            v-for="(items, i) in item.comboItems"
                            :key="i"
                            style="border-top: 1px solid gray"
                          >
                            <td
                              style="
                                border: none;
                                width: -webkit-fill-available;
                              "
                            >
                              <i
                                style="color: rgb(24, 28, 50)"
                                class="fas fa-cube"
                              ></i>
                              {{ items.productName }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                    <td></td>
                    <td class="text-right align-middle font-size-print">
                      {{ item.quantity }}
                    </td>
                    <td class="text-right align-middle font-size-print">
                      <p
                        v-if="
                          item.billItemType === 1 || item.billItemType === 3
                        "
                      >
                        {{ formatMoney(item.price) }}
                      </p>
                    </td>
                    <td class="text-right align-middle font-size-print">
                      <p
                        v-if="
                          item.billItemType === 1 && item.discountType === 1
                        "
                      >
                        {{ formatMoney(item.discount) }}
                      </p>
                      <p
                        v-if="
                          item.billItemType === 1 &&
                          item.discountType === 2 &&
                          item.discountAmount === 0
                        "
                      >
                        {{ formatMoney(item.discount) }}
                      </p>
                      <p
                        v-if="
                          item.billItemType === 1 &&
                          item.discountType === 2 &&
                          item.discountAmount > 0
                        "
                      >
                        {{ formatMoney(item.discountAmount) }}%
                        <br />
                        <span
                          style="font-style: italic"
                          v-if="
                            item.billItemType === 1 &&
                            item.discountType === 1 &&
                            item.discountAmount > 0
                          "
                          >{{ formatMoney(item.discount) }}</span
                        >
                        <span
                          style="font-style: italic"
                          v-if="
                            item.billItemType === 1 &&
                            item.discountType === 2 &&
                            item.discountAmount > 0
                          "
                          >{{
                            formatMoney(item.discount * item.quantity)
                          }}</span
                        >
                      </p>
                    </td>
                    <td class="text-right align-middle font-size-print">
                      <p
                        v-if="
                          item.billItemType === 1 || item.billItemType === 3
                        "
                      >
                        {{ calTotalAmountItem(item) }}
                      </p>
                    </td>
                  </tr>
                </table>

                <p class="content-info font-size-print">
                  Điểm tích lũy còn lại: {{ bill.customerPoint }}
                </p>
                <p
                  class="content-info mb-5 font-size-print"
                  v-if="bill.returnFee > 0"
                >
                  <span class="label-color">Phí trả hàng:</span>
                  {{ formatMoney(bill.returnFee) }}
                </p>
                <p
                  class="content-info mb-5 font-size-print"
                  v-if="bill.pointUse > 0"
                >
                  <span class="label-color">Tiêu điểm tích lũy:</span>
                  : {{ bill.subtractPoint }} điểm(
                  {{ formatMoney(bill.pointUse) }})
                </p>
                <p
                  class="content-info mb-5 font-size-print"
                  v-if="bill.depositAmount > 0"
                >
                  <span class="label-color">Tiền đã cọc:</span>
                  : {{ formatMoney(bill.depositAmount) }}
                </p>
                <p
                  class="content-info mb-5 font-size-print"
                  v-if="bill.transferFromOrder > 0"
                >
                  <span class="label-color">Tiền đã chuyển khoản:</span>
                  : {{ formatMoney(bill.transferFromOrder) }}
                </p>
                <p
                  class="content-info mb-5 font-size-print"
                  v-if="bill.moneyCredit > 0"
                >
                  <span class="label-color">Tiền đã quẹt thẻ:</span>
                  : {{ formatMoney(bill.moneyCredit) }}
                </p>
                <p
                  class="content-info mb-5 font-size-print"
                  v-if="bill.moneyInstallment > 0"
                >
                  <span class="label-color">Tiền đã trả góp:</span>
                  : {{ formatMoney(bill.moneyInstallment) }}
                </p>
                <p class="content-info mb-5 font-size-print">
                  <span class="label-color">Chiết khấu trên hóa đơn</span>
                  : {{ formatMoney(bill.discountValue) }}
                </p>
                <p class="content-info mb-5 font-size-print">
                  <span class="label-color">Tổng tiền phải trả</span>
                  :
                  <span class="font-size-price">{{
                    formatMoney(bill.totalPay)
                  }}</span>
                </p>

                <p
                  class="content-info font-size-print"
                  style="margin-bottom: 0px"
                >
                  <span class="label-color font-size-print"
                    >Tổng tiền bằng chữ: {{ bill.textAmount }}</span
                  >
                </p>

                <div v-if="bill.orderNote">
                  <p class="content-info font-size-print">
                    <span class="label-color">Ghi chú của hóa đơn </span>
                    : {{ bill.orderNote }}
                  </p>
                </div>

                <div v-if="bill.warrantyNote">
                  <p class="content-info font-size-warranty">
                    <span class="label-color">Ghi chú bảo hành </span>
                    <span>: {{ bill.warrantyNote }}</span>
                  </p>
                </div>

                <div v-if="bill.productUsages.length > 0">
                  <p class="content-info font-size-product-info">
                    <span class="label-color">Lưu ý khi sử dụng sản phẩm:</span>
                  </p>
                  <ul
                    style="
                      padding-left: 15px;
                      line-height: 20px;
                      margin-top: 5px;
                      margin-left: 5px;
                      list-style: circle;
                    "
                    class="font-size-product-info"
                  >
                    <div
                      v-for="(productUsage, index) in bill.productUsages"
                      :key="index"
                    >
                      <li v-if="productUsage.note">
                        <p
                          class="font-size-product-info"
                          style="margin-bottom: 0px !important"
                        >
                          {{ productUsage.productName }}:
                        </p>
                        <div class="content-product font-size-product-info">
                          {{ productUsage.note }}
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>

                <b-container class="bv-example-row">
                  <b-row cols="12">
                    <b-col md="4" class="text-center">
                      <div>
                        <p>
                          <span class="label-color font-size-print"
                            >BÁN HÀNG</span
                          >
                        </p>
                        <p class="font-size-name">
                          {{ bill.saleName }}
                        </p>
                      </div>
                    </b-col>
                    <b-col md="4" class="text-center">
                      <div>
                        <p class="text-center">
                          <span class="label-color font-size-print"
                            >THU NGÂN</span
                          >
                        </p>
                        <p class="font-size-name text-center">
                          {{ bill.cashierName }}
                        </p>
                      </div>
                    </b-col>
                    <b-col md="4" class="text-center">
                      <div>
                        <p class="text-center">
                          <span class="label-color font-size-print"
                            >KHÁCH HÀNG</span
                          >
                        </p>
                        <p class="font-size-name text-center">
                          {{ bill.customerName }}
                        </p>
                      </div>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                </b-container>
              </div>
            </b-row>
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import {
  convertNumberToText,
  makeToastFaile,
  makeToastSuccess,
} from '@/utils/common';

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      bill: {
        billNumber: '',
        storeId: '',
        customerName: '',
        customerPhone: '',
        listBillItem: [],
        totalAmount: 0,
        textAmount: '',
        orderNote: '',
        saleName: '',
        cashierName: '',
        storeAddress: '',
        createdAt: '',
        warrantyNote: '',
        depositAmount: 0,
        transferFromOrder: 0,
        customerPoint: 0,
        totalPay: 0,
        moneyCredit: 0,
        moneyInstallment: 0,
        pointUse: 0,
        subtractPoint: 0,
        totalItemAmount: 0,
        discountValue: 0,
        customerAddress: '',
        productUsages: [],
      },

      searchIds: '',
      bills: [],
      isBusy: false,
    };
  },
  created() {},
  computed: {
    totalBills() {
      return this.bills.length;
    },
    totalBillsError() {
      return this.bills.filter((x) => x.notExist).length;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: '/bills' },
      { title: 'In hóa đơn' },
    ]);
  },
  methods: {
    validateGetBill: function (searchIds) {
      const searchTrim = searchIds ? searchIds.trim() : '';
      const splits = searchTrim.split(',');
      const notNumber = [];
      splits.map((item) => {
        if (!Number.isInteger(Number(item))) {
          notNumber.push(item);
        }
      });
      if (notNumber.length) {
        return [false, notNumber.join() + ' không phải là số'];
      }
      const distinct = [...new Set(splits)];
      return [true, distinct];
    },
    removeBill() {
      this.bills = [];
    },
    getBill: async function () {
      const searchIds = this.searchIds ? this.searchIds.trim() : '';
      if (!searchIds) {
        return;
      }
      const [isValid, data] = this.validateGetBill(searchIds);
      if (!isValid) {
        return makeToastFaile(data);
      }
      this.isBusy = true;
      for (const billNumber of data) {
        const exist = this.bills.find(
          (x) => x.billNumber === Number(billNumber)
        );
        if (!exist) {
          const bill = await this.getBillById(billNumber);
          if (bill) {
            this.bills.push(bill);
          } else {
            this.bills.push({
              notExist: true,
              billNumber: billNumber,
            });
          }
        }
      }
      this.isBusy = false;

      makeToastSuccess('Lấy hoá đơn thành công');
    },
    getBillById: function (billNumber) {
      return ApiService.get(`bills/${billNumber}`)
        .then((response) => {
          const { data } = response.data;
          return this.mappingBill(data);
        })
        .catch(() => {
          return null;
        });
    },
    mappingBill(data) {
      const totalPay = this.calcTotalPay(data);
      const amountConvertText = convertNumberToText(totalPay);
      const amountTextUpperCase = this.upperCaseFirstLetter(amountConvertText);

      const bill = {
        listBillItem: this.mappingBillItems(data.billItems),
        billNumber: data.billNumber,
        storeId: data.storeId,
        storeAddress: data.storeAddress,
        customerName: data.customerName,
        customerPhone: data.customerPhone,
        totalAmount: data.totalAmount,
        orderNote: data.customerNote,
        saleName: data.saleName,
        cashierName: data.cashierName,
        createdAt: data.createdAt,
        warrantyNote: data.warrantyNote,
        depositAmount: data.depositAmount,
        transferFromOrder: data.transferFromOrder,
        customerPoint: data.customerPoint,
        moneyInstallment: data.moneyInstallment,
        moneyCredit: data.moneyCredit,
        subtractPoint: data.subtractPoint ? data.subtractPoint : 0,
        pointUse: data.pointToMoney ? data.pointToMoney : 0,
        totalItemAmount: data.totalItemAmount,
        discountValue: data.discountValue,
        customerAddress: data.customerBillAddress,
        totalPay: totalPay,
        textAmount: amountTextUpperCase,
        returnFee: data.returnFee,
      };
      console.log('bill', bill);
      const productUsages = this.handleProductUsage(bill.listBillItem);
      bill.productUsages = productUsages;

      return bill;
    },
    countQuantityBillItems(billItems) {
      return billItems.reduce(
        (result, element) => result + element.quantity,
        0
      );
    },
    mappingBillItems(billItems) {
      let stt = 0;
      return billItems.reduce((result, element) => {
        if (element.billItemType === 1) stt++;

        const productPrice = element.productPrice || 0;
        let discountItemAmount = element.discountAmount;

        if (element.discountType * 1 === 2 && element.discountAmount <= 100) {
          discountItemAmount = (productPrice * element.discountAmount) / 100;
        }

        const billItem = {
          stt: stt,
          id: element.productId,
          name: element.productName,
          code: element.productCode,
          price: productPrice,
          type: element.productType,
          quantity: element.quantity,
          imeiCode: element.imeiCode,
          totalPrice: element.quantity * productPrice - discountItemAmount,
          discount: discountItemAmount,
          comboItems: element.listProductInCombo,
          billItemType: element.billItemType,
          discountAmount: element.discountAmount,
          discountType: element.discountType * 1,
          productNote: element.productNote ? element.productNote.trim() : null,
        };
        result.push(billItem);
        return result;
      }, []);
    },
    handleProductUsage(items) {
      const productUsages = items.reduce((result, product) => {
        const item = result[product.productId] || null;
        const productNote = product.productNote;
        if (!item && productNote) {
          result[product.productId] = {
            productId: product.productId,
            productName: product.productName,
            note: productNote,
          };
        }
        return result;
      }, {});
      return Object.values(productUsages);
    },
    calcTotalPay(data) {
      const sum = [
        'depositAmount',
        'transferFromOrder',
        'moneyCredit',
        'moneyInstallment',
        'pointUse',
      ].reduce((result, item) => {
        const amount = data[item] || 0;
        return result + amount;
      }, 0);
      const totalPay = data.totalAmount - sum;
      return totalPay;
    },
    upperCaseFirstLetter(text) {
      return text.charAt(1).toUpperCase() + text.slice(2).toLowerCase();
    },
    printData: async function () {
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}

          <style>
          .font-size-print {font-size: 20px !important;}
          .font-size-name{ font-size: 20px !important;}
           .font-size-warranty{ font-size: 26px !important;}
           .font-size-product-info{ font-size: 20px !important;}
           .font-size-price{ font-size: 30px !important; font-weight: bold !important;}
           </style>
        </head>
        <body`);
      WinPrint.document.write(`${prtHtml}

        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, 500);

      // WinPrint.close();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    calTotalAmountItem(item) {
      let result = 0;
      if (item.discountType === 2) {
        result = item.price * item.quantity - item.discount * item.quantity;
      } else {
        result = item.price * item.quantity - item.discount;
      }
      return this.formatMoney(result);
    },
  },
};
</script>

<style scoped>
.break-page-print {
  page-break-after: always;
  page-break-inside: avoid;
  margin-bottom: 20px;
}
.header-print {
  text-align: center;
  width: 100%;
}
.label-color {
  color: black;
  font-weight: 600;
}
.content-info {
  font-weight: 500;
}
.table-bordered-print th {
  border: 0.5px solid #474545;
}
.table-bordered-print td {
  border: 0.5px solid #474545;
}
.font-size-print {
  font-size: 13px;
}
.font-size-name {
  font-size: 13px;
}
.font-size-warranty {
  font-size: 13px;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
}
/* @media print {
  .col-xs-12 {
    width: 30%;
    float: left;
  }
} */
/* @media print {
  @page {
    size: 5.8in 8.3in;
  }
} */
/* @media print {
  @page {
    font-size: 130%;
  }
} */
.content-product {
  line-height: 1.375;
  white-space: pre-wrap;
  font-size: 13px;
  margin-top: 4px;
}
</style>
